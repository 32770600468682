$colorGreenDark: rgb(40, 95, 25) !default;
$colorGreenLight: rgb(175, 203, 8) !default;

$fixedWidthTextStdPadding: 50px !default;
$fixedWidthTextStdPaddingSmall: 30px !default;
$fixedWidthTextListPadding: 40px !default;
$fixedWidthTextQuotePadding: 15px !default;
$fixedWidthTextQuoteMargin: 38px !default;
$fixedWidthTextQuoteMarginSmall: 18px !default;
$fixedWidthTextQuoteBorder: 2px !default;

$lineNumberOffset: 8px !default;
$lineNumberOffset4: 3px !default;

$bodyFont: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$deadlineCircleFont: 'sans-serif' !default;
$sidebarActionFont: 'sans-serif' !default;
$buttonFont: 'sans-serif' !default;
$motionFixedFont: Courier, sans-serif !default;
$motionStdFontSize: 14px !default;
$blockquote-font-size: 14px !default;

$motionListAmendmentColor: $colorGreenLight !default;

$headingFont: 'sans-serif' !default;
$headingFontItalic: false !default;
$headingPrimaryText: black !default;
$headingPrimaryBackground: white !default;
$headingPrimarySize: 15px !default;
$headingSecondaryText: black !default;
$headingSecondaryBackground: white !default;
$headingSecondarySize: 15px !default;
$headingTertiaryText: black !default;
$headingTertiaryBackground: white !default;
$headingTertiarySize: 15px !default;
$uppercaseTitles: false !default;

$headingFontUppercase: true !default;
$headingFontBold: true !default;
$headingTextShadow: true !default;

$menuFont: 'sans-serif' !default;
$menuLink: blue !default;
$menuActive: black !default;
$adminHintColor: #ee0101 !default;
$colorDelLink: #ee0101 !default;
$errorColor: #ee0101 !default;
$focusBorderColor: rgba(102, 175, 233, 1) !default;
$focusShadowColor: rgba(102,175,233,0.6) !default;

$sidebarBackground: white !default;
$sidebarBackgroundGradient: true !default;
$sidebarTextColor: white !default;
$sidebarActionFont: 'sans-serif' !default;
$sidebarUppercase: true !default;
$createMotionBtnColor: white !default;
$createMotionTextColor: white !default;

$bookmarkAmendmentBackground: black !default;
$bookmarkAmendmentText: white !default;
$bookmarkCommentColor: black !default;

$commentPadding: 10px !default;
$commentBackground: #fafafa !default;
$commentBorder: lightgrey !default;

$wizardBackground: #f9f9f9 !default;
$wizardBorderColor: #d4d4d4 !default;
$wizardStepInactiveBackground: #ededed !default;
$wizardStepInactiveColor: #999999 !default;
$wizardStepActiveBackground: #f1f6fc !default;
$wizardStepActiveColor: #afcb08 !default;

$homeAgendaTitlePrefixWidth: 90px !default;

$screen-lg-min: 10000px; // deaktivieren
$container-md: 1024px !default;
$sidebarWidth: 256px !default;
$screenMinWith: 800px !default;

$icon-font-path: "../fonts/" !default;
$line-height-base: 1.5;
$contentBorderRadius: 10px !default;
$use-box-shadow: true !default;
