@charset "UTF-8";

@import "variables";

.consultationIndex {
  overflow-wrap: break-word;
  .myImotionList {
    .widthdrawn .firstLine {
      text-decoration: line-through;
    }
    .initiator .firstLine a {
      font-weight: bold;
    }
  }
  .translateWidget {
    float: right;
    margin-left: 20px; // For the edit button
  }
}

.motionList {
  .date {
    color: #757676;
    display: block;
    @media (min-width: $screenMinWith) {
      margin-left: 20px;
    }
    @media (max-width: $screenMinWith - 1) {
      margin-left: 12px;
    }
    position: absolute; // no float, otherwise height gets recalculated wrong when sorting agenda items
    margin: 0;
    @media (max-width: $screen-sm-max) {
      position: relative;
      top: 0;
      right: 0;
      float: right;
    }

    .edited {
      font-size: 0.8em;
      display: inline-block;
      padding-right: 10px;
    }
  }

  .motion {
    position: relative;
    width: 100%;
    overflow-wrap: break-word;

    &:last-child {
      padding-bottom: 0;
    }

    > .date {
      top: 12px;
      right: 12px;
    }

    > .title {
      margin-bottom: 3px;

      .motionIcon {
        width: 21px;
        margin-left: -24px;
        color: $colorLinksLight;
      }

      a {
        color: $colorLinks;
        display: inline-block;
        &:hover, &:focus {
          color: darken($colorLinks, 20%);
        }
      }

      a, .motionLink {
        font-weight: bold;
        text-indent: 0;
        @if ($font-size-base < 16) {
          font-size: 16px;
          line-height: 18px;
        }
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
      }

      .pdfLink {
        font-size: 13px;
        color: $colorLinksLight;
        margin-left: 10px;
        display: inline-block;
        font-weight: normal;
        a:hover {
          text-decoration: none;
          color: darken($colorLinksLight, 20%);
        }
      }
    }
  }

  .motion.withdrawn {
    .motionTitle, .motionPrefix {
      text-decoration: line-through;
    }
  }
  .amendment.withdrawn {
    .amendmentTitle {
      text-decoration: line-through;
    }
  }
  .motion.modified, .motion.withdrawn, .motion.moved {
    > .title a * {
      opacity: 0.4;
    }
    .amendment > a {
      opacity: 0.4;
    }
    h4.amendments {
      opacity: 0.65;
    }
  }
  .amendment.modified, .amendment.withdrawn {
    > .title a {
      opacity: 0.4;
    }
  }

  h4.amendments.amendmentsToggler {
    margin-top: -5px;
    button {
      padding-left: 0;
    }
    &.closed {
      .glyphicon-chevron-up {
        display: none;
      }
    }
    &.opened {
      .glyphicon-chevron-down {
        display: none;
      }
    }
  }
  ul.amendments.closed {
    display: none;
  }

  ul.amendments {
    list-style-type: none;
    margin: 10px 0 20px 0;
    padding: 0;

    > li {
      margin-bottom: 3px;
      position: relative;

      .motionIcon {
        margin-right: 10px;
      }

      > a {
        font-weight: bold;
        margin-right: 5px;
      }
      > .date {
        top: 0;
        right: -8px;
      }
    }
  }
  .status {
    font-style: italic;
    color: $text-color;
  }
  .womenQuota {
    font-size: 0.8em;
    margin-left: 10px;
    display: inline-block;
  }
}

.motionListStd, .motionListFilterTags {
  list-style-type: none;
  margin: 0 0 40px;
  padding: 0;
}

.motionListWithoutAgenda {
  .motion {
    padding: 12px 20px 17px 50px;

    > .date {
      display: block;
    }
    > .title {
      padding-right: 65px;
      .motionPrefix {
        display: inline-block;
        &:after {
          content: ':';
        }
      }
    }
    .info {
      font-style: italic;
      color: rgb(115, 115, 115);
    }
    .clearfix {
      display: none;
    }
  }
  h4.amendments {
    display: none;
  }
  ul.amendments {
    > li {
      > .date {
        display: block;
      }
    }
  }
}

.motionListBelowAgenda {
  .motion {
    padding: 12px 30px 17px 30px;

    > .date {
      display: none;
    }
    > .title {
      font-family: $headingFont;
      .motionIcon {
        display: none;
      }
      .motionPrefix {
        word-break: break-all;
        word-wrap: break-word;
        width: $homeAgendaTitlePrefixWidth;
        float: left;
        left: 30px;
        top: 13px;
      }
      .motionTitle {
        display: block;
        margin-left: $homeAgendaTitlePrefixWidth + 5px;
      }
      .pdfLink {
        display: none;
      }
    }
    .info {
      display: block;
      margin-left: $homeAgendaTitlePrefixWidth + 5px;
    }
    .clearfix {
      clear: both;
    }
  }
  ul.amendments {
    margin-bottom: 10px;
    @media screen and (min-width: 600px) {
      margin-left: $homeAgendaTitlePrefixWidth + 5px;
    }
    > li {
      > a {
        float: left;
        width: $homeAgendaTitlePrefixWidth;
        left: 0;
        top: 0;
      }
      > .date {
        display: none;
      }
    }
  }
  h4.amendments {
    @media screen and (min-width: 600px) {
      margin-left: $homeAgendaTitlePrefixWidth + 5px;
    }
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: $headingFont;
    @if ($font-size-base < 16) {
      font-size: 14px;
    } @else {
      font-size: inherit;
      line-height: inherit;
    }
    font-weight: bold;
    color: $motionListAmendmentColor;
  }
}

.motionListPetitions {
  .status {
    font-weight: bold;
    font-style: normal;
  }
}

.noMotionsYet {
  font-style: italic;
}

$agendaMarginPerLevel: 50px;
.motionListWithinAgenda {
  list-style-type: none;
  margin: 15px 0 0;
  padding: 0;
  position: relative;

  @if ($font-size-base < 16) {
    .motion {
      > .title {
        a {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  ol {
    list-style-type: none;
    margin: 0 0 0 30px;
    padding: 0;
    clear: both; // clears the "add motion"-button, which would be affecting the "add entry" line
  }
  ul.motions {
    list-style-type: none;
    padding: 0;
    @media (min-width: $screenMinWith) {
      margin: 0 0 0 50px;
    }
    @media (max-width: $screenMinWith - 1) {
      margin: 0 0 0 26px;
    }
  }
  ul.amendments {
    > li > .date {
      right: 3px;
    }
  }

  .agendaItemAdder {
    padding-left: 35px;
    margin-bottom: 0;
    margin-top: -4px;
    display: flex;
    flex-direction: row;
    height: 20px; // to prevent invalid height of placeholder while dragging
    overflow: hidden;
    .addEntry {
      flex: 0;
      flex-basis: 25%;
    }
    .addDate {
      flex: 0;
      flex-basis: 25%;
    }
    .spacer {
      flex: 1;
    }
    .showTimes {
      flex: 0;
      flex-basis: 25%;
      font-weight: normal;
    }
  }
  html.no-touchevents & .agendaItemAdder {
    visibility: hidden;
  }
  html.no-touchevents &:hover > .agendaItemAdder, html.no-touchevents & ol.agenda:hover > .agendaItemAdder {
    visibility: visible;
  }

  li.agendaItem {
    border: solid 1px transparent; // Otherwise: strange behavior when moving items
    @media (min-width: $screenMinWith) {
      padding-left: 20px;
    }
    @media (max-width: $screenMinWith - 1) {
      padding-left: 16px;
    }
    position: relative;

    .delAgendaItem, .delAgendaItem:link, .delAgendaItem:visited {
      color: $colorDelLink;
      position: absolute;
      top: 5px;
      right: 10px;
    }
    &.agendaItemDate .delAgendaItem {
      top: 30px;
    }

    > div {
      margin-bottom: 5px;
      @media (min-width: $screenMinWith) {
        padding: 5px 10px 10px;
      }
      @media (max-width: $screenMinWith - 1) {
        padding: 5px 10px 10px 0;
      }

      > h3 {
        overflow: visible; // Times
        padding: 3px;
        font-weight: normal;
        html.no-touchevents & .delAgendaItem {
          opacity: 0;
        }
        html.no-touchevents &:hover .delAgendaItem {
          opacity: 1;
        }
        html.no-touchevents & .delAgendaItem:focus {
          opacity: 1;
        }

        .editAgendaItem {
          font-size: 0.7em;
          margin-left: 10px;
        }
        html.no-touchevents & .editAgendaItem {
          opacity: 0;
        }
        html.no-touchevents &:hover .editAgendaItem {
          opacity: 1;
        }
        html.no-touchevents & .editAgendaItem:focus {
          opacity: 1;
        }
      }

      .motionCreateLink {
        float: right;
        text-align: left;
        margin-bottom: 7px;
        text-indent: -7px;
        padding-left: 18px;
        display: block;
      }
    }

    &.editing {
      > div > h3 {
        display: none;
      }
      > div > .agendaItemEditForm {
        display: flex;
      }
      > div > .agendaDateEditForm {
        display: flex;
      }
      > ol > .agendaItemAdder {
        visibility: hidden !important;
      }
    }

    .motion {
      > .date {
        top: 0;
        right: 3px;
      }
      > .title {
        margin-right: 75px;
      }
      h4.amendments {
        @if ($font-size-base < 16) {
          font-size: 16px;
        } @else {
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
  }
  li.agendaItem.agendaItemDate {
    > div {
      padding-top: 30px;
    }
    h3 {
      text-decoration: underline;
      margin-bottom: 8px;
      font-weight: bold;
    }
  }

  &.agendaListEditing {
    padding-top: 20px;
    padding-bottom: 20px;
    li.agendaItem {
      > div {
        margin-bottom: 0;
        padding-bottom: 0;
        padding-top: 0;
      }
      &.agendaItemDate > div {
        padding-top: 30px;
      }
    }
  }

  .agendaItemEditForm {
    display: none;
    /* display: flex */
    width: 100%;
    flex-direction: row;
    padding-bottom: 6px;

    .code {
      margin-right: 10px;
      flex-grow: 0;
    }

    .time {
      margin-right: 10px;
      flex-grow: 0;
      flex-basis: 80px;
      width: 80px;
      input {
        padding-left: 7px;
        padding-right: 7px;
      }
      .input-group-addon {
        cursor: pointer;
        padding-left: 7px;
        padding-right: 7px;
      }
    }

    .title {
      margin-right: 10px;
      flex-grow: 1;
    }

    .extraSettings {
      .dropdown-toggle {
        margin-right: 10px;

        .glyphicon-wrench {
          position: absolute;
          top: 6px;
          left: 10px;
        }
        .caret {
          margin-top: 13px;
        }
      }
      .dropdown-menu {
        min-width: 240px;
      }
    }

    .delete {
      flex-basis: 30px;
      flex-grow: 0;
    }

    .agendaMotionsRow {
      padding-top: 5px;
      font-size: 0.8em;
      color: gray;
    }

    .motionType {
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 0;
    }
  }
  &.noShowTimes {
    h3 .time {
      display: none;
    }
    .agendaItemEditForm {
      .time {
        display: none;
      }

      .code {
        width: 80px;
        flex-basis: 80px;
      }

      .motionType {
        max-width: 170px;
        flex-basis: 170px;
      }
    }
  }
  &.showTimes {
    > li {
      padding-left: 50px; // Place for the time
    }
    h3 .time {
      float: left;
      color: gray;
      font-size: 0.8em;
      padding-top: 3px;
    }
    li.agendaItem h3 .time {
      margin-left: -50px;
    }
    li.agendaItem li.agendaItem h3 .time {
      margin-left: -50px - $agendaMarginPerLevel;
    }
    li.agendaItem li.agendaItem li.agendaItem h3 .time {
      margin-left: -50px - $agendaMarginPerLevel * 2;
    }
    .agendaItemEditForm {
      .code {
        flex-basis: 50px;
      }

      .motionType {
        flex-basis: 140px;
      }
    }
  }
  .agendaDateEditForm {
    display: none;
    /* display: flex */
    width: 100%;
    flex-direction: row;

    .date {
      width: 285px;
      flex-basis: 285px;
      flex-grow: 0;
      margin-right: 10px;

      .input-group-addon {
        cursor: pointer;
      }
    }

    .title {
      flex-grow: 1;
      margin-right: 10px;
    }

    .delete {
      flex-basis: 30px;
      flex-grow: 0;
    }
  }

  h2, h3 {
    margin: 0 0 5px;
    font-size: 18px;
  }
  .moveHandle {
    float: left;
    font-size: 1.5em;
    color: lightgrey;
    margin-left: -27px;
    cursor: move;
  }
  &.showTimes > li > div .moveHandle { // Only move the top-level handlers to the side
    margin-left: -67px;
  }
  html.no-touchevents & li.agendaItem > div > .moveHandle {
    display: none;
  }
  html.no-touchevents & li.agendaItem:hover > div > .moveHandle {
    display: block;
  }

  .movePlaceholder {
    border: dotted 1px gray;
  }
}

.motionListFilterTags {
  margin: 0;

  .sortitem.motion {
    margin-bottom: 20px;
  }
  .info {
    margin: 0;
  }

  .abstract {
    margin-left: 0;
    color: gray;
  }

  .stats {
    float: right;

    .comments {
      background-color: rgb(226, 0, 122);
    }

    .amendments {
      background-color: rgb(175, 203, 8);
    }

    .comments, .amendments {
      display: inline-block;
      padding: 3px 6px;
      margin-left: 10px;
      color: white;
      border-radius: 3px;
    }
  }
}

.motionListFilter {
  .tagList {
    text-align: center;

    .btn {
      margin: 2px 4px;
    }

    margin-bottom: 15px;
  }

  .searchBar {
    margin-bottom: 15px;
  }
}


.expandableRecentComments {
  margin-bottom: 15px;

  .commentList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .commentListHolder {
    position: relative;

  }
  .showAllComments {
    display: none;
    text-align: center;
    button {
      font-weight: normal;
    }
  }
  &.shortened {
    .showAllComments {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 11;
    }
    .commentListHolder {
      overflow: hidden;
      max-height: 340px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        height: 70px;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
        z-index: 10;
      }
    }
  }

  .motionCommentHolder {
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;

    &:nth-child(even) .motionComment {
      margin-right: 0;
    }

    &:nth-child(odd) .motionComment {
      margin-left: 0;
    }
  }

  .motionComment {
    margin-bottom: 5px;
    font-size: 0.9em;


    .commentHeader {
      padding: 5px;
    }

    .date {
      padding: 5px 5px 0 0;

    }

    .commentText {
      padding: 0 5px 5px 5px;
      min-height: 59px; // 3 lines
      .glyphicon {
        font-size: 0.8em;
      }
    }

    .motionLink {
      padding: 0 5px 5px 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


.motionListTags {
  #tagList {
    display: block;
    list-style-type: none;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;

    > li {
      display: inline-block;
      padding: 10px;
      background-color: #e2007a;
      border-radius: 3px;
      font-size: 16px;
      margin: 10px;

      > a:link, #tag_list > li > a:visited {
        color: white;
      }
    }
  }

  .motionTable {
    width: 100%;

    th {
      border-bottom: solid 1px $colorGreenLight;
      font-size: 0.80em;
      line-height: 2.5em;
      font-weight: 600;
    }
    td {
      vertical-align: top;
      padding: 0.75em 0em 0.75em 0em;
    }
    tr.motion {
      border-top: solid 1px $colorGreenLight;
      &:first-child {
        border-top: none;
      }
    }
    tr.amendment {
      .titleCol {
        .pdfLink {
          font-weight: 400;
        }
        .titleLink {
          font-weight: 400;
        }
      }
    }
    .prefixCol {
      width: 15%;
    }
    .titleCol {
      width: 45%;

      .pdfLink {
        font-weight: 600;
        font-size: 0.8em;
        float: right;
        margin-right: 20px;
      }
      .titleLink {
        font-weight: 600;
        a:link, a:visited {
          color: black;
        }
      }
    }
    .initiatorCol {
      width: 35%;
    }
    .unscreened {
      .titleCol {
        .pdfLink {
          display: none;
        }
        .titleLink {
          a:link, a:visited {
            font-weight: 400;
            color: gray;
          }
        }
      }
    }
  }
}


.consultationPhasesWizard {
  margin-bottom: 40px;

  .wizard {
    border-bottom: none;
    @include wizardHeight(70px);
  }

  .title {
    line-height: 20px;
    font-weight: bold;
    margin-top: 3px;
  }
  .permissions {
    line-height: 14px;
    font-size: 12px;
  }
}
